import React, { useState, useEffect, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import Select, { components } from "react-select";
import { useStateWithCallbackLazy } from "use-state-with-callback";

import TranslationContext from "../../../Context/Translation";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import { generateId } from "../../../Utils/FunctionalUtils";

const TopNavigationMenuSection = ({
  properties: {
    primaryLink,
    primaryLinkType,
    secondaryLink,
    secondaryLinkType,
    logo,
    topLinks,
    navigation,
    languages,
    activeLanguage,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const [active, setActive] = useState();

  const [language, setLanguage] = useStateWithCallbackLazy({
    label: activeLanguage.twoLetterIsoCode.toUpperCase(),
    value: activeLanguage.twoLetterIsoCode,
  });

  const history = useHistory();

  useEffect(() => {
    // without this check it will initially fire twice: first time on initial load when
    // translation.length is 0 and second time when translation will be set in Context
    // also we ensure this way that activeTranslation and newTranslation below will not be undefined
    if (translation.length) {
      const activeTranslation = translation.find((lang) => lang.active === 1);
      const newTranslation = translation.find((lang) => lang.twoLetterIsoCode === language.value);
      // prevention of 2 pushes on language change - first when the language state is updated
      // and second when the Context is updated by the new page translation data (after push)
      // + prevention of push from / to /landing as side effect
      if (activeTranslation.link !== newTranslation.link) {
        // when we are moving between pages with the same language everything is ok
        // when we are changing language using select everything is ok
        // BUT when we are on /en/landing page and clicking on logo ("/" route)
        // there will be a problem: we do not update the language value in our state,
        // so it will be 'en' in selector and 'de' in our context, which will lead to
        // multiple puhses from page to page. To prevent that we need to update our
        // language value in state, BUT there is a catch - we need to fire push only
        // AFTER the new state will be setup, that's why we use a custom hook here,
        // because setters are asynchronous
        setLanguage(
          {
            label: activeTranslation.twoLetterIsoCode.toUpperCase(),
            value: activeTranslation.twoLetterIsoCode,
          },
          // this callback will fire after the state will be updated, receives new value
          // as a parameter
          () => {
            // for a proper work of hyphens: auto
            document.documentElement.setAttribute("lang", newTranslation.twoLetterIsoCode);

            history.push(newTranslation.link);
          },
        );
      }
    }
  }, [language, translation, history, setLanguage]);

  useEffect(() => {
    const navToggler = document.getElementById("navbarNav");

    if (navToggler) {
      navToggler.addEventListener("show.bs.collapse", () => {
        document.body.classList.add("overflow-hidden");
      });

      navToggler.addEventListener("hide.bs.collapse", () => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  }, []);

  const onLanguageChanged = (langObj) => {
    // problem here is, translation path for current page is written in
    // pageContainer not in Navigation
    // not sure, how to solve this in the right way

    setLanguage(langObj);
  };

  const renderLanguageswitch = () => {
    if (languages.length > 1) {
      const styles = {
        indicatorSeparator: () => {},
        control: (base) => ({
          ...base,
          fontSize: 14,
          fontWeight: 600,
          // borderRadius: "24px",
          borderRadius: "0",
          borderColor: "transparent",
          borderWidth: "0",
          backgroundColor: "transparent",
          // paddingLeft: "0.5rem",
          // paddingRight: "0.5rem",
          paddingLeft: "0",
          paddingRight: "0",
          paddingTop: "0",
          paddingBottom: "0",
          boxShadow: "none",
          cursor: "pointer",
          color: "#000000",
        }),
        singleValue: (base) => ({
          ...base,
          color: "#000000",
          fontWeight: 500,
        }),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
          paddingRight: 3,
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          padding: 0,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        menu: (base) => ({
          ...base,
          fontSize: 16,
          fontWeight: 400,
          top: 38,
          left: 2,
        }),
        option: (base, { isSelected }) => ({
          ...base,
          cursor: "pointer",
          fontWeight: isSelected ? 700 : 400,
          paddingTop: 12,
          paddingBottom: 12,
          backgroundColor: "#ffffff !important",
          color: "#000000 !important",

          ".option-text": {
            position: "relative",
          },

          "&:hover": {
            ".option-text::after": {
              content: '""',
              display: "block",
              height: 1,
              position: "absolute",
              bottom: -5,
              left: 0,
              right: 0,
              backgroundColor: "#000000",
            },
          },
        }),
      };

      const options = languages.map((lang) => {
        return {
          label: lang.twoLetterIsoCode.toUpperCase(),
          value: lang.twoLetterIsoCode,
        };
      });

      return (
        <div className="language">
          <Select
            aria-label="Language Select"
            styles={styles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: (props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <components.DropdownIndicator {...props}>
                  <svg
                    id="SVGDoc"
                    width="14"
                    height="9"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 14 9"
                  >
                    <title>chevron-down</title>
                    <g transform="rotate(90 7 4.5)">
                      <path
                        // eslint-disable-next-line max-len
                        d="M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z"
                        fill="#df1f38"
                        fillOpacity="1"
                      />
                    </g>
                  </svg>
                </components.DropdownIndicator>
              ),
              Option: ({ children, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <components.Option {...props}>
                  <span className={`option-text language-select ${children}`}>{children}</span>
                </components.Option>
              ),
              SingleValue: ({ children, ...props }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <components.SingleValue {...props}>
                  <span className={`option-text language-select ${children}`}>{children}</span>
                </components.SingleValue>
              ),
            }}
            isSearchable={false}
            options={options}
            value={language}
            onChange={onLanguageChanged}
          />
        </div>
      );
    }

    return <></>;
  };

  const renderNavigation = () => {
    const activeTranslation = translation.find((lang) => lang.active === 1);

    return navigation?.map((navigationItem) => {
      const anchorLink =
        activeTranslation.twoLetterIsoCode === "en"
          ? `/en/#${generateId(navigationItem)}`
          : `/#${generateId(navigationItem)}`;

      return (
        <li key={`key_${anchorLink}`} className="nav-item">
          <a
            className={`nav-link ${anchorLink === active ? "active" : ""}`.trim()}
            href={anchorLink}
            onClick={(e) => {
              e.preventDefault();

              history.push(anchorLink);

              setActive(anchorLink);
            }}
          >
            {navigationItem.tx_ahoii_toolkit_section_name}
          </a>
        </li>
      );
    });
  };

  const renderTopLink = (link) => {
    if (link.publicUrl?.includes("http") || link.url?.includes("http")) {
      return (
        <a href={link.publicUrl || link.url} target={link.target} className="toplinks-link">
          {link.title}
        </a>
      );
    }

    return (
      <NavLink
        to={link.publicUrl || link.url}
        target={link.target}
        activeClassName="active"
        className="toplinks-link"
        // aria-current="false"
      >
        {link.title}
      </NavLink>
    );
  };

  return (
    <nav className="navbar navbar-expand-xxl navbar-light navbar-mp fixed-top">
      {topLinks?.length > 0 && (
        <div className="navbar-toplinks d-none d-xxl-flex">
          <div className="container">
            <ul className="toplinks">{topLinks.map((topLink) => renderTopLink(topLink.link))}</ul>
          </div>
        </div>
      )}

      <div className="container menu-section top-navigation">
        <NavLink className="navbar-brand" to="/">
          <Image image={logo[0]} />
        </NavLink>

        <div className="me-3 d-none d-xxl-block">{renderLanguageswitch()}</div>

        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <div className="toggler-open">
            <span />
            <span />
            <span />
          </div>
          <div className="toggler-close">
            <i className="close" />
          </div>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="navbar-collapse-inner">
            <div className="d-block d-xxl-none">{renderLanguageswitch()}</div>
            <ul className="navbar-nav me-md-3">{renderNavigation()}</ul>

            <form className="links">
              <LinkSwitcher
                linkType={primaryLinkType}
                link={primaryLink}
                additionalClasses="btn-sm me-md-3"
              />

              <span className="navbar-link">
                <LinkSwitcher
                  linkType={secondaryLinkType}
                  link={secondaryLink}
                  additionalClasses="link-sm"
                />
              </span>
            </form>

            {topLinks?.length > 0 && (
              <div className="navbar-toplinks d-flex d-xxl-none">
                <div className="container">
                  <ul className="toplinks">
                    {topLinks.map((topLink) => renderTopLink(topLink.link))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

TopNavigationMenuSection.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default TopNavigationMenuSection;
