import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import PropTypes from "prop-types";

import Pagination from "react-paginate";
import Select, { components } from "react-select";

import TranslationContext from "../../../Context/Translation";
import ElementSwitcher from "../../ContentElements/ElementSwitcher";

import { getApi } from "../../../Utils/ApiUtils";

/**
 * one column layout full width
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NewsListLayout = ({ content }) => {
  const { translation } = useContext(TranslationContext);

  const settings = useRef({
    categories: [...content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.list],
    tags: [...content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.list],
    defaultCategory: [
      ...content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.list,
    ][0],
    defaultTag: [...content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.list][0],
    currentCategory: content.colPos1.filter((el) => el.type === "news_pi1")[0].content.data.settings
      .current,
    currentTag: content.colPos1.filter((el) => el.type === "news_pi1")[1].content.data.settings
      .current,
    pagination: content.colPos0[0].content.data.pagination,
  });

  const [news, setNews] = useState(content);
  const [category, setCategory] = useState(
    settings.current.currentCategory.minSlug || settings.current.defaultCategory.minSlug,
  );
  const [tag, setTag] = useState({
    label: settings.current.currentTag.title || settings.current.defaultTag.title,
    value: settings.current.currentTag.minSlug || settings.current.defaultTag.minSlug,
  });
  const [page, setPage] = useState(content.colPos0[0].content.data.pagination.current);

  const onCategorySelect = useCallback((e) => {
    e.preventDefault();

    const {
      target: { name },
    } = e;

    setCategory(name);
    setPage(1);
  }, []);

  const onTagSelect = useCallback((tagObj) => {
    setTag(tagObj);
    setPage(1);
  }, []);

  const onPageSelect = useCallback(({ selected }) => {
    setPage(selected + 1);
  }, []);

  const getUrl = useCallback(() => {
    const activeTranslation = translation.find((lang) => lang.active === 1).twoLetterIsoCode;

    const languageUrl = activeTranslation !== "de" ? activeTranslation : "";
    const baseUrl = content.colPos0[0].content.data.settings.baseSlug || "";
    const categoryUrl = category !== settings.current.defaultCategory.minSlug ? `/${category}` : "";
    const tagUrl = tag.value !== settings.current.defaultTag.minSlug ? `/${tag.value}` : "";
    const pageUrl = page !== 1 ? `/page-${page}` : "";

    return `${languageUrl}${baseUrl}${categoryUrl}${tagUrl}${pageUrl}`;
  }, [translation, category, tag, page, content.colPos0]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await getApi(getUrl());

      if (data.content?.colPos0?.length) {
        setNews(data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }, [getUrl]);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  const renderCategoryList = useCallback(
    (categories) => {
      return (
        categories.length > 1 && (
          <div key={`key_${categories.id || Math.random()}`} className="col-md-9 col-lg-10">
            <nav className="nav categories">
              {categories.map((categoryItem) => {
                return (
                  <a
                    key={`key_${categoryItem.title || Math.random()}`}
                    className={`nav-link ${category === categoryItem.minSlug ? "active" : ""}`}
                    href="#"
                    name={categoryItem.minSlug}
                    onClick={onCategorySelect}
                  >
                    {categoryItem.title}
                  </a>
                );
              })}
            </nav>
          </div>
        )
      );
    },
    [category, onCategorySelect],
  );

  const renderTagsList = useCallback(
    (tags) => {
      const styles = {
        indicatorSeparator: () => {},
        control: (base) => ({
          ...base,
          fontSize: 14,
          fontWeight: 600,
          borderRadius: "24px",
          borderColor: "#A4A4AE",
          borderWidth: "2px",
          backgroundColor: "transparent",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
          paddingTop: "0",
          paddingBottom: "0",
          boxShadow: "none",
          cursor: "pointer",
          "&:hover": {
            borderColor: "#A4A4AE",
          },
          "&:focus": {
            borderColor: "#A4A4AE",
          },
        }),
        menu: (base) => ({
          ...base,
          fontSize: 16,
          fontWeight: 400,
        }),
        option: (base, { isFocused, isSelected }) => ({
          ...base,
          cursor: "pointer",
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isSelected ? "#00c5cd" : isFocused ? "#e9ecef" : "#ffffff",
        }),
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all .2s ease",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
      };

      const options = tags.map((tagItem) => {
        const tagTitle = tagItem.title;

        return {
          label: tagTitle[0].toUpperCase() + tagTitle.slice(1),
          value: tagItem.minSlug,
        };
      });

      return (
        tags.length > 1 && (
          <div key={`key_${tags.id || Math.random()}`} className="col-md-3 col-lg-2">
            <Select
              aria-label="Tag Select"
              options={options}
              styles={styles}
              onChange={onTagSelect}
              value={tag}
              components={{
                DropdownIndicator: (props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <components.DropdownIndicator {...props}>
                    <svg
                      id="SVGDoc"
                      width="16"
                      height="9"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 13 7"
                    >
                      <g>
                        <g>
                          <title>chevron-down</title>
                          <path
                            // eslint-disable-next-line max-len
                            d="M1.04003,0.61695c0.08061,-0.08081 0.19005,-0.12623 0.30419,-0.12623c0.11414,0 0.22358,0.04542 0.30419,0.12623l4.85157,4.85243v0l4.85157,-4.85243c0.168,-0.168 0.44038,-0.168 0.60838,0c0.168,0.168 0.168,0.44038 0,0.60838l-5.15577,5.15576c-0.08061,0.08081 -0.19005,0.12623 -0.30419,0.12623c-0.11414,0 -0.22358,-0.04542 -0.30419,-0.12623l-5.15577,-5.15577c-0.08081,-0.08061 -0.12623,-0.19005 -0.12623,-0.30419c0,-0.11414 0.04542,-0.22358 0.12623,-0.30419z"
                            fill="#7a7e83"
                            fillOpacity="1"
                          />
                        </g>
                      </g>
                    </svg>
                  </components.DropdownIndicator>
                ),
              }}
            />
          </div>
        )
      );
    },
    [tag, onTagSelect],
  );

  const renderNewsNavigation = useCallback(() => {
    const newsList = news.colPos0.filter((el) => el.type === "news_pi1");
    let classes = "";

    if (newsList.length) {
      classes = newsList[0].appearance.bgColorClass;
    }

    return (
      <div className={classes}>
        <div className="container">
          <div className="row justify-content-between pb-medium pt-medium">
            {renderCategoryList(settings.current.categories)}

            {renderTagsList(settings.current.tags)}
          </div>
        </div>
      </div>
    );
  }, [renderCategoryList, renderTagsList, news.colPos0]);

  return (
    <>
      {news.colPos1.filter((el) => el.type !== "news_pi1").length > 0 && (
        <ElementSwitcher contentElements={news.colPos1.filter((el) => el.type !== "news_pi1")} />
      )}

      <div className="news-list">
        {news.colPos1 && renderNewsNavigation()}

        {news.colPos0 && (
          <ElementSwitcher contentElements={news.colPos0.filter((el) => el.type === "news_pi1")} />
        )}

        <div className="mt-small">
          {settings.current.pagination?.numberOfPages > 1 && (
            <div className="container pb-small">
              <div className="row">
                <div className="col-12">
                  <nav aria-label="News pagination">
                    <Pagination
                      previousLabel={<> &lsaquo;</>}
                      nextLabel={<>&rsaquo;</>}
                      breakLabel="..."
                      pageCount={settings.current.pagination.numberOfPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={3}
                      containerClassName="pagination justify-content-center"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      breakClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      activeClassName="active"
                      forcePage={settings.current.pagination.current - 1}
                      onPageChange={onPageSelect}
                    />
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

NewsListLayout.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default NewsListLayout;
