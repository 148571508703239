import React from "react";
import PropTypes from "prop-types";

import DividerSwitcher from "../ContentElements/Divider/DividerSwitcher";
import Image from "../Atoms/Image";
import Text from "../Atoms/Text";
import Headline from "../Atoms/Headline";

const BlockFooter = ({ content }) => {
  const colspan = 12 / content.blockItems.length;

  const renderSocialLinks = () => {
    return content.socialMedia.links?.map((socialMediaLink) => {
      return (
        <a
          key={`key_${socialMediaLink.link.url || Math.random()}`}
          href={socialMediaLink.link.url}
          noopener="true"
          noreferrer="true"
          className="link link-secondary link-inline"
          target="_blank"
          rel="noreferrer"
        >
          <i className={`fab fa-${socialMediaLink.className || socialMediaLink.type} fa-2x`} />
        </a>
      );
    });
  };

  function renderBlockItem(item, index) {
    return (
      <div
        className={`col-md-${colspan} ${index > 0 ? "mt-4 mt-md-0" : ""}`.trim()}
        key={`key_${item.header || Math.random()}`}
      >
        <Headline headerType="h6" headline={item.header} />

        {(item.bodytext || item.blockLinks.length > 0) && (
          <div className="mt-xsmall">
            {item.elementtype === "text" ? (
              <Text textType="html" text={item.bodytext} />
            ) : (
              item.blockLinks.map((link) => {
                return (
                  <div key={`key_${link.link.url || Math.random()}`}>
                    <a className="link" href={link.link.url}>
                      {link.link.title}
                    </a>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    );
  }

  const renderFooterLinks = () => {
    return content.footerLinks?.map((footerLink) => {
      return (
        <a
          key={`key_${footerLink.link.url || Math.random()}`}
          className="link link-sm link-divider d-inline-block"
          href={footerLink.link.url}
        >
          {footerLink.link.title}
        </a>
      );
    });
  };

  return (
    <div className="container footer block">
      {content.socialMedia.links.length > 0 && (
        <>
          <div className="row social-media justify-content-between mb-small">
            <div className="col-md-3 mb-4 mb-md-0">
              <Image image={content.logo[0]} />
            </div>

            <div className="col-md-6">
              <Headline headerType="h6" headline={content.socialMedia.label} />
              <div>{renderSocialLinks()}</div>
            </div>
          </div>

          <div className="row mb-small">
            <div className="col-12">
              <DividerSwitcher layout="standard" />
            </div>
          </div>
        </>
      )}

      {content.blockItems.length > 0 && (
        <>
          <div className="row block-items mb-small">
            <Headline headerType="h6" headline={content.blockItemsLabel} />

            {content.blockItems?.map((item, index) => {
              return renderBlockItem(item, index);
            })}
          </div>

          <div className="row mb-xsmall">
            <div className="col-12">
              <DividerSwitcher layout="standard" />
            </div>
          </div>
        </>
      )}

      <div className="row footerlinks justify-content-between">
        <div className="col-md-4 order-2 order-md-1 text-center text-md-start mt-4 mt-md-0">
          <div className="teaser fs-7">{content.copyright}</div>
        </div>
        <div className="col-md-6 order-1 order-md-2 text-center text-md-end">
          {renderFooterLinks()}
        </div>
      </div>

      {content.additionalImage?.[0] && (
        <div className="row additional-image mt-small">
          <div className="col-12">
            <Image image={content.additionalImage[0]} />
          </div>
        </div>
      )}
    </div>
  );
};

BlockFooter.propTypes = {
  content: PropTypes.instanceOf(Object),
};

export default BlockFooter;
