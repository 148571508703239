import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const SimpleStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    mirrored,
    animation,
  },
}) => {
  const getImageContainer = useCallback(() => {
    let inlineStyles = {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "inherit",
      height: "100%",
      objectFit: "cover",
    };

    if (mirrored === "1") {
      inlineStyles = { ...inlineStyles, right: "auto", left: 0 };
    }

    return (
      <div className="col-md-5 d-flex justify-content-center order-1 order-md-2 mb-small mb-md-0">
        <Image image={media[0]} style={inlineStyles} additionalClasses="stage-img" />
      </div>
    );
  }, [media, mirrored]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-md-7 order-2 order-md-1 mb-medium mb-md-0">
        <Preline preline={subline} />

        <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

        {teaser && (
          <div className="mt-xsmall">
            <Text textType="html" text={teaser} />
          </div>
        )}

        {(headerLink || secondaryLink) && (
          <div className="mt-small" data-aos={animation.links.default}>
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                additionalClasses="btn-lg"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        )}
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored === "1") {
      return (
        <div className="row flex-row-reverse align-items-center">
          {getTextContainer()}
          {getImageContainer()}
        </div>
      );
    }

    return (
      <div className="row align-items-center">
        {getTextContainer()}
        {getImageContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer]);

  return <div className="container stage simple d-flex">{getContent()}</div>;
};

SimpleStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SimpleStage;
