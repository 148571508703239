/* eslint-disable valid-typeof */
import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";
import TableSwitcher from "../../Atoms/Table/TableSwitcher";

import TranslationContext from "../../../Context/Translation";

import { createPerformanceChart } from "../../../Utils/ChartUtils";
import { chartColors } from "../../../Constants/ChartConstants";
import { debounce } from "../../../Utils/FunctionalUtils";
import { formatPercentage } from "../../../Utils/FormatUtils";

const StandardPerformanceChart = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    bottomTeaser,
    fonds,
    // bottomTeaser,
    animation,
  },
}) => {
  const { translation } = useContext(TranslationContext);

  const activeTranslation = translation.find((lang) => lang.active === 1);

  const [datesRange, setDatesRange] = useState({ startDate: new Date(), endDate: new Date() });
  const [valuesRange, setValuesRange] = useState({});

  /**
   * take care that we get an array to operate with
   * @returns {[]}
   */
  const getPerformance = useCallback(() => {
    const performance = {
      fonds: [], // array will contain objects with main fond data (name, isin, color)
      data: [], // array will contain objects with all fonds values united by date
    };

    if (fonds.length > 0) {
      fonds.forEach((item, index) => {
        // render 5 series max
        if (index > 4) {
          return;
        }

        const data = item.data[0];

        const fond = {
          isin: data.isin,
          name: data.name,
          color: chartColors[index],
        };

        performance.fonds.push(fond);

        data.performance.allTime.forEach((dataSet) => {
          const existingDate = performance.data.find(
            (performanceData) => performanceData.date === dataSet.date,
          );

          if (existingDate) {
            // if an object with such date exists - add a new isin and value there
            existingDate[data.isin] = dataSet.value;
          } else {
            // otherwise - create a new entry
            const newItem = {};

            newItem[data.isin] = dataSet.value;
            newItem.date = dataSet.date;

            performance.data.push(newItem);
          }
        });
      });
    }

    performance.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return performance;
  }, [fonds]);

  const getDynamicHeading = useCallback(() => {
    const startDate = datesRange.startDate.toLocaleDateString().replace(/\//g, "-");
    const endDate = datesRange.endDate.toLocaleDateString().replace(/\//g, "-");

    return `${startDate} - ${endDate}`;
  }, [datesRange]);

  const getDynamicValue = useCallback(
    (isin) => {
      if (valuesRange[isin]) {
        const { startValue } = valuesRange[isin];
        const { endValue } = valuesRange[isin];

        return (((endValue - startValue) / startValue) * 100).toFixed(2);
      }

      return 0;
    },
    [valuesRange],
  );

  const getTable = useCallback(() => {
    const wknFound = fonds.filter((item) => {
      return item.data[0].wkn.length;
    });

    let table = [];

    if (wknFound.length) {
      const tableHeadingsDE = [
        "",
        getDynamicHeading(),
        "Heute",
        "Seit Jahresbeginn",
        "1 Jahr",
        "Wertpapierkennnummer",
      ];

      const tableHeadingsEN = [
        "",
        getDynamicHeading(),
        "Today",
        "This Year",
        "1 Year",
        "Identification number",
      ];

      table = [activeTranslation.twoLetterIsoCode === "de" ? tableHeadingsDE : tableHeadingsEN];

      fonds.forEach((item) => {
        const data = item.data[0];

        table.push([
          data.name,
          getDynamicValue(data.isin) ? formatPercentage(getDynamicValue(data.isin)) : "",
          typeof data.performance.today.value !== "undefined" ||
          typeof data.performance.today.value !== null
            ? formatPercentage(data.performance.today.value)
            : "",
          typeof data.performance.thisYear.value !== "undefined" ||
          typeof data.performance.thisYear.value !== null
            ? formatPercentage(data.performance.thisYear.value)
            : "",
          typeof data.performance["1year"].value !== "undefined" ||
          typeof data.performance["1year"].value !== null
            ? formatPercentage(data.performance["1year"].value)
            : "",
          data.wkn,
        ]);
      });
    } else {
      const tableHeadingsDE = ["", getDynamicHeading(), "Heute", "Seit Jahresbeginn", "1 Jahr"];
      const tableHeadingsEN = ["", getDynamicHeading(), "Today", "This Year", "1 Year"];

      table = [activeTranslation.twoLetterIsoCode === "de" ? tableHeadingsDE : tableHeadingsEN];

      fonds.forEach((item) => {
        const data = item.data[0];

        table.push([
          data.name,
          getDynamicValue(data.isin) ? formatPercentage(getDynamicValue(data.isin)) : "",
          typeof data.performance.today.value !== "undefined" ||
          typeof data.performance.today.value !== null
            ? formatPercentage(data.performance.today.value)
            : "",
          typeof data.performance.thisYear.value !== "undefined" ||
          typeof data.performance.thisYear.value !== null
            ? formatPercentage(data.performance.thisYear.value)
            : "",
          typeof data.performance["1year"].value !== "undefined" ||
          typeof data.performance["1year"].value !== null
            ? formatPercentage(data.performance["1year"].value)
            : "",
        ]);
      });
    }

    return table;
  }, [activeTranslation, fonds, getDynamicHeading, getDynamicValue]);

  useEffect(() => {
    const amChart = createPerformanceChart(
      `chartdiv_performance_chart_${Object.keys(fonds).length}`,
      getPerformance(),
      `chartdiv_performance_chart_controls_${Object.keys(fonds).length}`,
      activeTranslation.twoLetterIsoCode,
    );

    amChart.xAxes.values[0].events.on(
      "selectionextremeschanged",
      debounce((ev) => {
        setDatesRange({
          startDate: new Date(ev.target.minZoomed),
          endDate: new Date(ev.target.maxZoomed),
        });

        const newValuesRange = {};

        ev.target.series.values.forEach((series) => {
          newValuesRange[series.dataFields.valueY] = {
            startValue: series.dataItems.values[series.startIndex].valueY,
            endValue: series.dataItems.values[series.endIndex - 1].valueY,
          };
        });

        setValuesRange(newValuesRange);
      }, 100),
    );

    return () => amChart.dispose();
  }, [activeTranslation, fonds, getPerformance]);

  return (
    <div className="container performance-chart standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}
        </div>
      </div>

      {(headerLink || secondaryLink) && (
        <div className="row justify-content-center mt-small" data-aos={animation.links.default}>
          <div className="col-md-10">
            <div className="links">
              <LinkSwitcher
                linkType={headerLinkType}
                link={headerLink}
                additionalClasses="btn-lg"
              />

              <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
            </div>
          </div>
        </div>
      )}

      {Object.keys(fonds).length > 0 && (
        <>
          <div className="row mt-xlarge">
            <div className="col-12">
              <div className="chart">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <div
                      id={`chartdiv_performance_chart_controls_${Object.keys(fonds).length}`}
                      className="performance_chart_controls"
                    />
                  </div>
                </div>
                <div
                  id={`chartdiv_performance_chart_${Object.keys(fonds).length}`}
                  className="performance_chart"
                  style={{ height: "600px" }}
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-xlarge">
            <div className="col-10">
              <TableSwitcher tableType="secondary" table={getTable()} />

              {bottomTeaser && (
                <div className="mt-xsmall">
                  <Text textType="html" text={bottomTeaser} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

StandardPerformanceChart.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardPerformanceChart;
