export const COLOR_VIOLET = "violet";
export const COLOR_PURPLE = "purple";
export const COLOR_ORANGE = "orange";
export const COLOR_GREEN = "green";
export const COLOR_BLUE = "blue";

export const COLOR_PRIMARY = "#df1f38";
export const COLOR_PRIMARY_HOVER = "#93081b";
export const COLOR_SECONDARY = "#848687";
export const COLOR_SECONDARY_DISABLED = "#c6c6c6";
export const COLOR_TERTIARY = "#000000";
export const COLOR_LIGHT = "#edeef2";

export const baseColorHex = {
  primary: "223, 31, 56",
  secondary: "132, 134, 135",
  tertiary: "0, 0, 0",
  violet: "125, 87, 161",
  purple: "199, 26, 136",
  orange: "235, 102, 23",
  green: "68, 167, 31",
  bue: "0, 140, 214",
};

export const chartColors = [
  "primary",
  "secondary",
  "tertiary",
  "violet",
  "purple",
  "orange",
  "green",
  "blue",
];

export const { REACT_APP_AM_CHARTS_LICENCE, REACT_APP_AM_CHARTS_MAPS_LICENCE } = process.env;
