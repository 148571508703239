import React from "react";

import LinkSwitcher from "../Atoms/Link/LinkSwitcher";

const PageMissing = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
      <h1 className="display-2">Oh noes, something went wrong</h1>

      <div className="mt-large">
        <div className="links">
          <LinkSwitcher
            linkType="btn-primary"
            link={{ publicUrl: "/", title: "Go back" }}
            additionalClasses="btn-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default PageMissing;
