import React from "react";
import PropTypes from "prop-types";

import SimpleStage from "./SimpleStage";
import ViewportHeightStage from "./ViewportHeightStage";
import FullWidthStage from "./FullWidthStage";
import CenteredStage from "./CenteredStage";
import SingleSideStage from "./SingleSideStage";
import ComponentMissing from "../../Debug/ComponentMissing";

const StageSwitcher = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  media,
  maxHeight,
  viewportHeight,
  mirrored,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    mirrored,
    animation,
  };

  function switchStage() {
    switch (layout) {
      case "standard":
        return <SimpleStage properties={properties} />;
      case "100vh":
        return <ViewportHeightStage properties={properties} />;
      case "full-width":
        return <FullWidthStage properties={properties} />;
      case "centered":
        return <CenteredStage properties={properties} />;
      case "single-side":
        return <SingleSideStage properties={properties} />;
      default:
        return <ComponentMissing type="Stage" subType={layout} />;
    }
  }

  return switchStage();
};

StageSwitcher.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  media: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  maxHeight: PropTypes.string,
  viewportHeight: PropTypes.bool,
  mirrored: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default StageSwitcher;
