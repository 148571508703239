import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const MasonryNewsListItem = ({ news, linkTitle, hideDate, columnsPerRow, animation }) => {
  const inlineStyles = {
    backgroundImage: `url(${news.media?.[0]?.images.listViewFeaturedImage.publicUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    paddingTop: "75%",
  };

  const getClasses = useCallback(() => {
    const { col_xs: colXs, col_sm: colSm, col_md: colMd, col_lg: colLg } = columnsPerRow;

    return `col-${12 / colXs} col-sm-${12 / colSm} col-md-${12 / colMd} col-lg-${
      12 / colLg
    } d-flex`;
  }, [columnsPerRow]);

  return (
    <div className={getClasses()}>
      <div className="card w-100">
        <div className="card-image">
          <Image imageType="background" image={news.media?.[0]} style={inlineStyles} />
        </div>

        <div className="card-body">
          {!hideDate && <h6 className="news-list-date color-static">{news.datetime}</h6>}

          {news.slug && (
            <NavLink to={news.slug}>
              <Headline headerType="h4" headline={news.title} additionalClasses="color-static" />
            </NavLink>
          )}

          {news.teaser && (
            <div className="mt-xsmall">
              <Text
                textType="text"
                text={`${news.teaser.slice(0, 350)}...`}
                additionalClasses="color-static"
              />
            </div>
          )}

          {news.slug && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType="link-secondary"
                  link={{ publicUrl: news.slug, title: linkTitle }}
                  additionalClasses="color-static"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MasonryNewsListItem.propTypes = {
  news: PropTypes.instanceOf(Object),
  linkTitle: PropTypes.string,
  hideDate: PropTypes.bool,
  columnsPerRow: PropTypes.instanceOf(Object),
  animation: PropTypes.instanceOf(Object),
};

export default MasonryNewsListItem;
